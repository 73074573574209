<template>
  <div class="mapWrapper">
    <div class="commonMap" :id="id"></div>
    <div class="tool-box-wrapper" :style="'left:' + dynamicStyle + 'px'">
      <div
        v-for="(tool, index) in localConfig.tools"
        class="tool-box"
        :key="index"
        @click.stop="toolHandler(tool)"
        :style="
          (tool === 'hierarchy' && showMore.hierarchy) ||
          (tool === 'command' && showMore.command) ||
          (tool === 'search' && showMore.search) ||
          (tool === 'measure' && showMore.measure) ||
          (tool === 'marker' && showMore.marker)
            ? 'opacity: 1;'
            : ''
        "
      >
        <img :src="icons[tool]" />
        <div
          class="more-actions"
          v-if="tool === 'hierarchy'"
          v-show="showMore.hierarchy"
        >
          <label class="act-line">
            <input type="radio" v-model="mapLayer" value="平面" />
            <span>平面</span>
          </label>
          <label class="act-line">
            <input type="radio" v-model="mapLayer" value="卫星" />
            <span>卫星</span>
          </label>
          <hr />
          <!-- <label class="act-line">
                      <input type="checkbox" value="路网图" v-model="mapArea" />
                      <span>路网图</span>
                    </label> -->
          <label class="act-line">
            <input type="checkbox" value="地名" v-model="mapArea" />
            <span>地名</span>
          </label>
          <label class="act-line">
            <input type="checkbox" value="虚拟护栏" v-model="mapArea" />
            <span>虚拟护栏</span>
          </label>
        </div>
        <div
          class="more-actions measureBox"
          v-if="tool === 'measure'"
          v-show="showMore.measure"
        >
          <div @click.stop="stadiometryFn" :style="measureLineStyle">
            <span>测距</span>
          </div>
          <div @click.stop="mSurfaceFn" :style="measureAreaStyle">
            <span>测面</span>
          </div>
        </div>
      </div>
    </div>
    <!--搜索框-->
    <div class="searchBox" v-show="showMore.search">
      <i-input
        v-model="searchMsg"
        autosize
        prefix="ios-search"
        placeholder="搜索或输入您的地点"
        @keyup.enter.native="search"
      />
    </div>
    <!--返回图标-->
    <div
      class="goBack pointer"
      @click="$router.back(-1)"
      v-if="mapFlyingParams && mapFlyingParams.pathArr"
    >
      <img src="../../../assets/img/Icon/goBack.png" />
    </div>
    <!--3D切换-->
    <div
      class="switch-3d flex pointer"
      v-if="config.show3DSwitchBtn"
      :style="'right:' + dynamicStyle + 'px'"
      @click="switch3D"
    >
      <img src="../../../assets/img/statistics/switch.png" />
    </div>
    <!--发送目标点-->
    <div
      class="sendCommander"
      v-show="showMore.markerModal"
      v-if="config.onLineList"
      @click.stop=""
    >
      <div class="top">任务标记</div>
      <div class="no-data" v-if="!config.onLineList.length">
        暂时没有无人机在线!
      </div>
      <div class="coordinate">
        <p>地理位置</p>
        经度 <span>{{ sendCommanderOptions.local_data[0].lng }}</span
        ><br />
        纬度
        <span>{{ sendCommanderOptions.local_data[0].lat }}</span>
      </div>
      <div class="mark_info coordinate">
        <p>标注信息</p>
        <div>名称<input v-model="targetName" /></div>
        <div>
          <label>备注</label>
          <textarea v-model="targetRemarks" />
        </div>
      </div>
      <div class="mark_style coordinate">
        <p>标注样式</p>
        <div>
          图标
          <div
            style="
              background: #dbdbdb;
              display: inline-block;
              padding: 2px;
              vertical-align: middle;
              margin: 0 20px 0 8px;
              border-radius: 2px;
            "
          >
            <img :src="targetIcon" alt="" />
          </div>
          <button @click="changeType = !changeType">更换</button>
          <ul v-show="changeType" class="icons" @click.stop="">
            <li>
              <img
                :src="navigation"
                alt="navigation"
                @click.stop="targetType(navigation, 'mark_state_navigation')"
              />
            </li>
            <li>
              <img
                :src="doubt"
                alt="doubt"
                @click.stop="targetType(doubt, 'mark_state_doubt')"
              />
            </li>
            <li>
              <img
                :src="set"
                alt="set"
                @click.stop="targetType(set, 'mark_state_set')"
              />
            </li>
            <li>
              <img
                :src="prohibit"
                alt="prohibit"
                @click.stop="targetType(prohibit, 'mark_state_prohibit')"
              />
            </li>
            <li>
              <img
                :src="warning"
                alt="warning"
                @click.stop="targetType(warning, 'mark_state_warning')"
              />
            </li>
          </ul>
        </div>
        <div>
          <button
            class="sendBtn"
            :loading="sendCommanderOptions.loading"
            @click.stop="submitSendCommander"
          >
            确定
          </button>
          <button class="sendBtn sendCancel" @click.stop="cancelSendCommander">
            取消
          </button>
        </div>
      </div>
    </div>
    <!--群体飞控-->
    <!-- <div style="opacity: 0; position: absolute; bottom: 0">
      <div class="sendControlMsg"
           ref="sendControlMsg">
        <i class="ivu-icon ivu-icon-ios-close-circle-outline"
           @click="cancelSendControlbox"></i>
        <Button type="primary"
                size="small"
                @click="sendControlMsgFn">进行远程控制
        </Button>
        <h1>将对以下飞手发送远程控制请求:</h1>

        <div class="dronePilotBox">
          <div class="dronePilot"
               v-for="(item, ind) in UAVSelectedList"
               :key="ind">
            <img class="headImg"
                 :src="item.head_icon_path"
                 alt="" />
            <div class="Divider"></div>
            <div class="msgBox">
              <h2>{{ item.name }}</h2>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 名称显隐 -->
    <Checkbox
      v-if="config.isLive"
      class="show_name"
      :style="'left:' + dynamicStyle + 'px'"
      border
      @on-change="changeName"
      :true-value="'block'"
      :false-value="'none'"
      v-model="showName"
      >显示名称
    </Checkbox>
    <!-- <Modal v-model="ControlModal"
           width="600"
           title="控制台"
           footer-hide
           draggable
           @on-cancel="cancelSendControlMsg"
           class-name="ControlModal1">
      <ControlModal :info="config.onLineList"
                    :UAVSelectedList="UAVSelectedList"
                    @sendSocketMsg="sendSocketMsg"></ControlModal>
    </Modal> -->
    <!-- 图片视频大图弹窗 -->
    <BigImgModelPath
      v-if="imgModal"
      :imgList="imgList"
      :idx="bigIndex"
      @closeBigImgModel="closeImgModal()"
    ></BigImgModelPath>
  </div>
</template>

<script>
let mapId = 0
import _ from 'lodash'

import search from '@/assets/img/Icon/search.png'
import measure from '@/assets/img/Icon/measure.png'
import hierarchy from '@/assets/img/Icon/hierarchy.png'
import zoomIn from '@/assets/img/Icon/jia.png'
import zoomOut from '@/assets/img/Icon/jian.png'
import fix from '@/assets/img/Icon/fix.png'
import marker from '@/assets/img/Icon/marker.png'
import command from '@/assets/img/Icon/command.png'
import avatar from '@/assets/img/statistics/ava1.png'
import sendMarker from '@/assets/img/Marker/Level_2_project.png'
import PlaneGreen from '@/assets/img/Marker/drone-green.png'
import PlaneRed from '@/assets/img/Marker/drone-red.png'
import PlaneYellow from '@/assets/img/Marker/drone-yellow.png'
import PlaneBlue from '@/assets/img/Marker/drone-blue.png'
import PlanePurple from '@/assets/img/Marker/drone-purple.png'
import Drone from '@/assets/img/Marker/drone.png'
import Drone_90 from '@/assets/img/Marker/drone_90.png'
// import uav from '@/assets/img/Marker/uav.png';
import cpRPAs from '@/utils/cpRPA.mixin.js'
// import ControlModal from './ControlModal.vue';
import Api from '@/utils/api.js'
import { GetNotFlyArea } from '@/utils/javaApi.js'
import navigation from '@/assets/img/Icon/mark_navigation.png'
import doubt from '@/assets/img/Icon/mark_doubt.png'
import set from '@/assets/img/Icon/mark_set.png'
import prohibit from '@/assets/img/Icon/mark_prohibit.png'
import warning from '@/assets/img/Icon/mark_warning.png'
import droneHeight from '@/assets/img/Icon/drone_hight.png'
import droneSpeed from '@/assets/img/Icon/drone_speed.png'
import droneDirection from '@/assets/img/Icon/drone_direction.png'
import dronePosition from '@/assets/img/Icon/drone_position.png'
import origin from '@/assets/img/Icon/origin.png'
import end from '@/assets/img/Icon/end.png'
import track_video from '@/assets/img/Icon/track_video.png'
import tp from '@/assets/img/Icon/target_point.png'
import BigImgModelPath from './bigImgModel_path.vue'
import trackPhotos from '@/assets/img/statistics/track_photos.png'

export default {
  name: 'commonMap',
  mixins: [cpRPAs],
  props: {
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    mapFlyingParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    lawFlyingParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    rapidInfo: {
      type: Object,
      default: () => {},
    },
    targetPoint: {
      type: Object,
      default: () => {},
    },
    imgList: {
      type: Array,
      default: () => [],
    },
  },
  components: { BigImgModelPath },
  data() {
    return {
      userInfo: {},
      mapType: '2d',
      icons: {
        search,
        measure,
        hierarchy,
        zoomIn,
        zoomOut,
        fix,
        marker,
        sendMarker,
        avatar,
        PlaneGreen,
        PlaneRed,
        PlaneYellow,
        PlaneBlue,
        PlanePurple,
        Drone,
        Drone_90,
        command,
        droneHeight,
        droneSpeed,
        droneDirection,
        dronePosition,
        origin,
        end,
        track_video,
        trackPhotos,
      },
      localConfig: {
        toolsMovingDistance: 0, // 右边有浮层 工具条定位需要改变的移动距离
        // search: 搜索 measure: 尺子 hierarchy: 图层 zoomIn: 放大 zoomOut: 缩小
        tools: ['hierarchy', 'zoomIn', 'zoomOut'],
      },
      showMore: {
        hierarchy: false,
        search: false,
        measure: false,
        marker: false,
        markerModal: false,
        command: false,
      },
      mapLayer: '平面',
      mapArea: ['虚拟护栏'],
      searchMsg: '',
      measureType: 'line', // area
      clickPointers: [],
      markList: [],
      lineList: [],
      lineCenterTextList: [],
      airPlaneMarkers: [], // 无人机marker
      sendMsgOptions: {
        // sendMsgBoxStyle: {
        //   top: "0",
        //   lfet: "0",
        // },
        height: 0,
        selectAll: false,
        target_id: [],
        speedRange: {
          min: 0,
          max: 300,
        },
        loading: false,
        longitude: 0,
        latitude: 0,
      },
      sendCommanderOptions: {
        type: 'commander_msg',
        target_id: [],
        loading: false,
        local_data: [
          {
            lat: 0,
            lng: 0,
            height: 120,
          },
        ],
        tag: 'mark_state_navigation', // 目标点类型

        msg_type: 3,
      },
      targetName: '新增目标点', // 标注点名称
      targetRemarks: '', // 标注点备注
      sendMarker: '', // 地图当前目标点集合
      // sMarker: null, //地图当前目标点
      rectangle: null,
      UAVSelectedList: [],
      sendControlMsgOptions: {
        sendControlMsgBoxStyle: {
          top: '0',
          lfet: '0',
        },
        height: 0,
        selectAll: false,
        target_id: [],
        speedRange: {
          min: 0,
          max: 300,
        },
        loading: false,
        longitude: 0,
        latitude: 0,
      },
      // infoWindowCommand:null
      //   ControlModal: false,
      //快拼区域
      editPath: null, //矩形四个角
      rapidDroneStatus: false, //拼图无人机飞行状态
      VideoShou: false, //拼图无人机直播状态
      targetIcon: navigation, // 目标点图标
      navigation,
      doubt,
      set,
      prohibit,
      warning,
      changeType: false,
      showName: 'block', // 显示名称
      NFzone: [], // 虚拟护栏合集
      targetPointIcon: tp, // app发送的目标点
      imgModal: false,
      bigIndex: 0,
    }
  },
  computed: {
    // 每个地图的id 有可能一个页面多个map
    id: {
      get() {
        return 'commonMap' + mapId++
      },
    },
    // 右下角按钮的位置
    dynamicStyle() {
      return (this.config || {}).toolsMovingDistance + 30 || 30
    },
    // 目标点面板的位置Target point

    measureLineStyle() {
      return {
        opacity: this.measureType === 'line' ? '1' : '0.6',
      }
    },
    measureAreaStyle() {
      return {
        opacity: this.measureType === 'area' ? '1' : '0.6',
      }
    },
    // 是否建线任务
    isLine() {
      return this.config.taskType !== 'area'
    },
  },
  watch: {
    mapLayer(val) {
      //卫星平面切换
      if (val == '平面') {
        this.map.remove(this.satelliteLayer)
        // this.map.add(this.roadNetLayer);
      } else if (val == '卫星') {
        this.map.add(this.satelliteLayer)
      }
    },
    mapArea(val) {
      //路网,虚拟护栏显示
      // 路网
      // if (val.indexOf("路网图") !== -1) {
      //     this.map.add(this.roadNetLayer);
      // } else {
      //     this.map.remove(this.roadNetLayer);
      // }
      // 虚拟护栏
      if (val.indexOf('虚拟护栏') !== -1) {
        this.NFzone.forEach((element) => {
          element.show()
        })
      } else {
        this.NFzone.forEach((element) => {
          element.hide()
        })
      }
      //显示默认标注
      if (val.indexOf('地名') !== -1) {
        let features = ['bg', 'road', 'building', 'point']
        this.map.setFeatures(features)
      } else {
        let features = ['bg', 'road', 'building']
        this.map.setFeatures(features)
      }
    },
    // 无人机轨迹回放处理 在父页面改变了curIndex导致触发了这里的回调 进行移动
    mapFlyingParams: {
      deep: true,
      handler: function (val) {
        if (val.playingStatus) {
          // 开始或者继续
          if (val.curIndex < val.allPlayingCounts) {
            this.renderPolylinePath()
          }
        } else {
          if (val.pathArr && val.pathArr.length) {
            // 初始化 数据已经准备完毕
            if (!this.drawMarker) {
              this.initPolylinePath()
            }
          }
          // 暂停或者停止
          if (this.drawMarker) {
            if (val.curIndex === val.allPlayingCounts) {
              this.drawMarker.stopMove()
            } else {
              this.drawMarker.pauseMove()
            }
          }
        }
      },
    },
    // 执法记录仪轨迹回放处理
    lawFlyingParams: {
      deep: true,
      handler: function (val) {
        if (val) {
          console.log('执法记录仪轨迹回放处理2')
          this.initLawPolylinePath(val)
        } else {
          console.log('执法记录仪轨迹回放数据错误')
        }
      },
    },
    // 通知配置
    config: {
      deep: true,
      handler: function (val) {
        // console.log("登录了的人信息",val);
        if (Object.keys(val).indexOf('onEventHandler') !== -1) {
          // 需要绑定额外的点击事件的时候才执行
          this.toggleMarkerClickOrDragEvent(val.onEventHandler || false)
        }
        if (Object.keys(val).indexOf('markLength') !== -1) {
          // setting页面更新了marker的数量 map需要同步页面
          this.updateMarkCounts()
        }
        // 编辑已有项目需要重新绘制下地图
        if (val.reDraw) {
          if (val.taskType === '3d-line') {
            this.mapType = '3d'
            this.initMap()
          }
          console.log('编辑页面，重绘地图……')
          this.drawLineOrArea(val.points)
          val.reDraw = false
        }
        // socket数据更新 地图同时更新
        if (val.updateBySocket) {
          // console.log(val)
          this.drawAirPlanes()
          val.updateBySocket = false
        }

        // 单个节点的高度更新
        if (val.UpdateBysinglePointList) {
          this.syncSinglePointList()
          val.UpdateBysinglePointList = false
        }

        // 自动规划的航线更新
        if (val.UpdateByNeedReRenderAutoLine) {
          // todo
          this.renderRPAPolylineHandler()
          val.UpdateByNeedReRenderAutoLine = false
        }
        //快速拼图任务
        if (val.rapidStatus) {
          console.log('快速拼图任务')
          if (this.editPath !== null) {
            // this.rapidMouseTool=this.mouseTool.rectangle({
            //   strokeColor:'red',
            //   strokeOpacity:0.5,
            //   strokeWeight: 6,
            //   fillColor:'blue',
            //   fillOpacity:0.5,
            //   // strokeStyle还支持 solid
            //   strokeStyle: 'solid',
            //   // strokeDasharray: [30,10],
            // });
            this.rapidRPAPolylineHandler(
              JSON.parse(sessionStorage.getItem('editPath'))
            )
          }
        } else {
        }
      },
    },
    //快速拼图的实时Socket信息
    rapidInfo: {
      deep: true,
      handler: function (val) {
        // console.log('快速拼图的实时Socket信息', val);
        if (val == null) {
          return
        }

        if (val.track_tag == 1 && !this.rapidDroneStatus) {
          //快速拼图正在飞行
          console.log('注册飞机')
          this.rapidDroneStatus = true
          this.rapidMarker = new AMap.Marker({
            position: [val.DroneLongitude, val.DroneLatitude],
            icon: new AMap.Icon({
              size: new AMap.Size(20, 20), //图标大小
              imageSize: new AMap.Size(20, 20),
              image: this.icons.Drone,
            }),
            offset: new AMap.Pixel(-10, -10),
            autoRotation: true,
          })
          this.map.add(this.rapidMarker)
          console.log('注册飞机结束', this.rapidMarker)
        }
        if (val.track_tag == 1) {
          console.log('更新飞机位置', this.rapidMarker)
          // this.rapidMarker.setPosition(new AMap.LngLat(
          //         val.DroneLongitude,
          //         val.DroneLatitude
          // ));
          this.rapidMarker.moveTo(
            new AMap.LngLat(val.DroneLongitude, val.DroneLatitude),
            this.config.rapidData.speed * 3.6
          )
          this.rapidMarker.setAngle(Number(val.DroneYaw))
        }
        if (val.track_tag == 0 && this.rapidDroneStatus) {
          this.rapidDroneStatus = false
        }

        if (val.IsPublish == 1 && !this.VideoShou) {
          this.VideoShou = true
          this.$_bus.$emit('RapidVideo', true)
        } else if (val.IsPublish == 0) {
          this.VideoShou = false
          this.$_bus.$emit('RapidVideo', false)
        }
      },
    },
    // 目标点配置
    targetPoint: {
      deep: true,
      handler: function (val) {
        console.log('watchtarget', val)
        let target = new AMap.Marker({
          position: new AMap.LngLat(val.longitude, val.latitude),
          icon: this.targetPointIcon, // 添加 Icon 图标 URL
          offset: new AMap.Pixel(-15, -15),
        })
        console.log('target', target)
        this.map.add(target)
      },
    },
    // 回放图片
    imgList(val) {
      val.forEach((ele, index) => {
        let img = new AMap.Marker({
          position: new AMap.LngLat(ele.lng, ele.lat),
          icon: new AMap.Icon({
            size: new AMap.Size(20, 20), // 图标尺寸
            image: this.icons.trackPhotos, // Icon的图像
            imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
          }),
          offset: new AMap.Pixel(-10, -10),
          zIndex: 150,
        })
        img.on('click', () => {
          this.bigImg(index)
        })
        this.map.add(img)
      })
    },
  },
  created() {
    this.localConfig.toolsMovingDistance =
      (this.config || {}).toolsMovingDistance || 0
    this.localConfig.tools = (this.config || {}).tools || [
      'hierarchy',
      'zoomIn',
      'zoomOut',
    ]
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))

    let _this = this
    this.initMap()
    if (!!this.mouseTool) {
      //监听地图画矢量图形结束
      this.mouseTool.on('draw', function (e) {
        //群体飞控
        // if (_this.showMore.command) {
        //   _this.ControlAreaEnd(e);
        // }
        //快速拼图任务
        if (_this.config.rapidStatus) {
          _this.rapidAreaEnd(e)
        }
      })
    }
    this.$_bus.$off('clearMap')
    this.$_bus.$on('clearMap', function () {
      _this.map.clearMap()
      _this.editPath = null
      _this.rapidDroneStatus = false //拼图无人机飞行状态
      _this.VideoShou = false
    })
    this.$_bus.$off('mapControlRapid')
    this.$_bus.$on('mapControlRapid', function (val) {
      if (val.isMapOperateLocked) {
        _this.rapidMouseTool = _this.mouseTool.rectangle({
          strokeColor: 'red',
          strokeOpacity: 0.5,
          strokeWeight: 6,
          fillColor: 'blue',
          fillOpacity: 0.5,
          // strokeStyle还支持 solid
          strokeStyle: 'solid',
          // strokeDasharray: [30,10]
        })
      } else {
        _this.mouseTool.close()
      }
    })
  },
  methods: {
    // 抛出发送消息的内容
    sendSocketMsg(data) {
      this.$emit('sendSocketMsg', data)
    },
    initMap() {
      // 引入瓦片图-平面
      let layer = []
      if (this.$online) {
        layer = new AMap.TileLayer()
        // 卫星图
        this.satelliteLayer = new AMap.TileLayer.Satellite()
        // 路网图
        // this.roadNetLayer = new AMap.TileLayer.RoadNet();
      } else {
        layer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
          },
          zIndex: 10,
        })
        this.satelliteLayer = new AMap.TileLayer({
          getTileUrl: function (x, y, z) {
            return Api.GaoDeStreetMap() + z + '/' + x + '/' + y + '.png'
          },
          zIndex: 10,
        })
      }
      // 创建地图
      if (this.mapType === '2d') {
        this.map = new AMap.Map(this.id, {
          resizeEnable: true, //是否监控地图容器尺寸变化
          // expandZoomRange:true,
          // zooms:[3,20],
          zoom: 14, //初始化地图层级
          center: this.$Center, //初始化地图中心点
          // crs: "EPSG4326", // 声明坐标系
          // 获取瓦片文件
          layers: [layer],
        })
        // this.map.add(xyzTileLayer);
        // 修改主题样式
        // this.map.setMapStyle('amap://styles/whitesmoke');
      } else {
        this.map = new AMap.Map(this.id, {
          pitch: 75, // 地图俯仰角度，有效范围 0 度- 83 度
          viewMode: '3D', // 地图模式
          resizeEnable: true, //是否监控地图容器尺寸变化
          zoom: 11, //初始化地图层级
          center: this.$Center,
          // center: [112.97935279, 28.21347823], //初始化地图中心点
        })
        // 修改主题样式
        // this.map.setMapStyle('amap://styles/whitesmoke');
      }
      // this.backCenter();
      //默认不显示标注
      var features = ['bg', 'road', 'building']
      this.map.setFeatures(features)
      // 无路网图、平面图，暂时屏蔽
      //   if (this.localConfig.tools.includes('hierarchy')) {
      //     //卫星图
      //     this.satelliteLayer = new AMap.TileLayer.Satellite();
      //     // this.satelliteLayer = new AMap.TileLayer({
      //     //   getTileUrl: function (x, y, z) {
      //     //     return Api.GaoDeSatelliteMap() + z + '/' + x + '/' + y + '.png';
      //     //   },
      //     //   zIndex: 10,
      //     // });
      //   }
      if (this.localConfig.tools.includes('measure')) {
        // 注册高德测量
        this.mouseTool = new AMap.MouseTool(this.map)
      }

      // 无人机航线自动规划
      this.initRPALine(this.map)

      // 初始化虚拟护栏
      this.initNFzone()
    },
    // 初始化轨迹
    initPolylinePath() {
      let config = this.mapFlyingParams

      if (!this.drawMarker) {
        this.drawMarker = new AMap.Marker({
          map: this.map,
          position: [config.pathArr[0][0], config.pathArr[0][1]],
          icon: this.icons.Drone_90,
          offset: new AMap.Pixel(-16, -16),
          autoRotation: true,
        })
        this.map.setFitView(this.drawMarker)
      }

      // 绘制轨迹
      if (!this.drawPolyline) {
        let fullPath = _.cloneDeep(config.pathArr)
        this.drawPolyline = new AMap.Polyline({
          map: this.map,
          path: fullPath,
          showDir: true,
          strokeColor: '#0AEFD5', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"     //线样式
          lineJoin: 'round',
          lineCap: 'round',
        })
        this.map.setFitView(this.drawPolyline)
      }

      if (!this.passedPolyline) {
        this.passedPolyline = new AMap.Polyline({
          map: this.map,
          // path: lineArr,
          strokeColor: '#AF5', //线颜色
          // strokeOpacity: 1,     //线透明度
          strokeWeight: 6, //线宽
          // strokeStyle: "solid"  //线样式
          lineJoin: 'round',
          lineCap: 'round',
        })
        this.map.setFitView(this.passedPolyline)
      }
    },
    //加载执法记录仪轨迹记录
    initLawPolylinePath(val) {
      console.log('进入加载执法记录仪轨迹记录方法')
      let path = eval(val.local_data).map((ele) => {
        return [parseFloat(ele.lng), parseFloat(ele.lat)]
      })
      console.log(path)
      this.drawPolyline = new AMap.Polyline({
        map: this.map,
        path: path,
        showDir: true,
        strokeColor: '#28F', //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"     //线样式
      })
      this.map.setFitView(this.drawPolyline)
      // 起点终点
      var originMarker = new AMap.Marker({
        map: this.map,
        position: path[0],
        icon: new AMap.Icon({
          size: new AMap.Size(20, 20), // 图标尺寸
          image: origin, // Icon的图像
          imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
        }),
        offset: new AMap.Pixel(-10, -10),
        autoRotation: true,
      })
      var endMarker = new AMap.Marker({
        map: this.map,
        position: path[path.length - 1],
        icon: new AMap.Icon({
          size: new AMap.Size(20, 20), // 图标尺寸
          image: end, // Icon的图像
          imageSize: new AMap.Size(20, 20), // 根据所设置的大小拉伸或压缩图片
        }),
        offset: new AMap.Pixel(-10, -10),
        autoRotation: true,
      })

      if (val.media) {
        val.media.forEach((item, ind) => {
          if (item.media_type == 2) {
            new AMap.Marker({
              map: this.map,
              position: [item.localtion.lng, item.localtion.lat],
              icon: new AMap.Icon({
                size: new AMap.Size(30, 30), // 图标尺寸
                image: track_video, // Icon的图像
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              autoRotation: true,
            })
          } else if (item.media_type == 1) {
            new AMap.Marker({
              map: this.map,
              position: [item.localtion.lng, item.localtion.lat],
              icon: new AMap.Icon({
                size: new AMap.Size(30, 30), // 图标尺寸
                image: item.path, // Icon的图像
                imageSize: new AMap.Size(30, 30), // 根据所设置的大小拉伸或压缩图片
              }),
              offset: new AMap.Pixel(-15, -15),
              autoRotation: true,
            })
          }
        })
      }
    },
    // 更新轨迹绘制的线条
    renderPolylinePath() {
      let config = this.mapFlyingParams
      let _this = this

      // if (!this.drawMarker.onMoving) {
      //   this.drawMarker.on("moving", function(e) {
      //     if (!_this.passedPath) {
      //       _this.passedPath = [];
      //     }
      //     _this.passedPath.concat(...(e.passedPath))
      //     _this.passedPolyline.setPath(this.passedPath);
      //   });
      //   this.drawMarker.onMoving = true
      // }

      // 先停 再以新速度开始
      let movePath = _.cloneDeep(
        config.pathArr
          .slice(config.curIndex, config.curIndex + 2)
          .map((ele) => new AMap.LngLat(ele[0], ele[1]))
      )
      if (movePath.length < 2) {
        return
      }

      this.drawMarker && this.drawMarker.pauseMove()
      // 执行moveTo的时候会一直报个错误 原因是 moveTo执行中this的指向改变了 导致报错 虽然不会导致线程挂掉 实在是没找到原因 高德地图new Maker构造的过程据说是异步的 导致的这个问题 实在是没办法了目前 就先这样吧
      try {
        this.drawMarker && this.drawMarker.moveAlong(movePath, config.speed)
      } catch (e) {
        // 高德的异步api错误无法被捕获 没得办法
        console.log(e)
      }

      // +1 落后正常飞行一步
      let passedPath = _.cloneDeep(
        config.pathArr
          .slice(0, config.curIndex + 1)
          .map((ele) => new AMap.LngLat(ele[0], ele[1]))
      )
      if (passedPath.length) {
        _this.passedPolyline.setPath(passedPath)
      }
    },
    // 绘制无人机
    drawAirPlanes() {
      // 用户marker
      // console.log('绘制无人机');
      this.clearAllNotOnLinePlanes()
      this.config.onLineList.forEach((ele) => {
        let markers = this.airPlaneMarkers.filter(
          (marker) => marker.uid.toString() === ele.user_id.toString()
        )

        if (!markers.length) {
          // console.log('新建用户');
          this.generateUserMarker(ele)
        } else {
          // console.log('更新数据',ele, markers);
          this.updateUserMarker(ele, markers)
        }
      })
    },
    // 清除不在线的用户和无人机
    clearAllNotOnLinePlanes() {
      this.airPlaneMarkers.forEach((ele, ind) => {
        // console.log("检验",ele.type);
        if (
          this.config.onLineList.findIndex(
            (item) => item.user_id === ele.uid
          ) === -1
        ) {
          this.map.remove(ele)
          // ele = null;
          this.airPlaneMarkers[ind] = null
          // this.airPlaneMarkers.splice(ind, 1);
        }
      })
      this.airPlaneMarkers = this.airPlaneMarkers.filter((e) => e !== null)

      // console.log("删除不在线",this.airPlaneMarkers,this.config.onLineList);
    },
    // 更新marker的一些dom属性
    updateMarkerDoms(marker, ele) {
      let newContent = this.getPlaneContent(ele)
      marker.setContent(newContent)
    },
    // 更新用户 无人机 链接线的数据
    updateUserMarker(ele, markers) {
      if (markers.length) {
        markers.forEach((marker) => {
          if (marker.type === 'user') {
            if (
              ele.UserLongitude &&
              ele.UserLatitude &&
              ele.UserLatitude !== 'NaN' &&
              ele.UserLongitude !== 'NaN' &&
              ele.UserLatitude !== '0' &&
              ele.UserLongitude !== '0'
            ) {
              //更改最新坐标
              marker.setPosition(
                new AMap.LngLat(
                  parseFloat(ele.UserLongitude) || 0,
                  parseFloat(ele.UserLatitude) || 0
                )
              )

              if (!marker.isAdded) {
                this.map.add(marker)
                marker.isAdded = true
              }
            }
          }
          if (marker.type === 'plane') {
            let L = ele.positionList.length
            if (L > 0) {
              let lastNewAirPlanePosition = new AMap.LngLat(
                parseFloat(ele.positionList[L - 1][0]) || 0,
                parseFloat(ele.positionList[L - 1][1]) || 0
              )
              marker.setPosition(lastNewAirPlanePosition)
              // marker.setAngle(Number(ele.DroneYaw));
              this.updateMarkerDoms(marker, ele)

              if (!marker.isAdded) {
                this.map.add(marker)
                marker.isAdded = true
                this.connectUserAndPlane(ele)
              }
            }
          }
          if (marker.type === 'line') {
            ele.positionList.splice(0, 1, [
              parseFloat(ele.UserLongitude),
              parseFloat(ele.UserLatitude),
            ])
            let lineArrPath = _.cloneDeep(ele.positionList)
            marker.setPath(lineArrPath)
          }
        })
      }
      let a = document.querySelectorAll('.amap-marker-content')
      if (a.length > 0) {
        a.forEach((element) => {
          element.style.whiteSpace = 'normal'
        })
      }
    },
    // 获取无人机颜色种类
    getPlaneIcon(color) {
      let icon
      //   switch (color) {
      //     case '#FF685B':
      //       icon = this.icons.PlaneRed;
      //       break;
      //     case '#FF9500':
      //       icon = this.icons.PlaneYellow;
      //       break;
      //     case '#4C98FF':
      //       icon = this.icons.PlaneBlue;
      //       break;
      //     case '#61D76B':
      //       icon = this.icons.PlaneGreen;
      //       break;
      //     case '#C179E5':
      //       icon = this.icons.PlanePurple;
      //       break;
      //     default:
      //       icon = this.icons.Drone;
      //   }
      icon = this.icons.Drone
      return icon
    },
    // 获取无人机dom
    getPlaneContent(ele) {
      let content = `
      <div class="marker-wrapper flex drone-icon" id="plane_${ele.user_id}">
        <div style="position:absolute;top:-30px;background:rgba(0,0,0,0.6);color:#FFF;white-space:pre;visibility:${
          this.showName == 'block' ? 'visible' : 'hidden'
        }" class="if_name_drone">${ele.DRONEMODEL}</div>
        <div class="plane-power-wrapper" style="position:absolute;top:-10px;">
          <div class="plane-power" style="width: ${parseFloat(
            ele.power || 0
          )}%;background-color: ${ele.comment || '#4de639'};">
          </div>
        </div>
        <div class="plane-avatar flex">
          <img style="transform: rotate(${
            ele.DroneYaw || 0
          }deg)" src="${this.getPlaneIcon(ele.comment)}" />
        </div>
        <div class="drone-info">
          <div class="drone-info-1">
          <p><img src="${ele.head_icon}" />${ele.name}</p><span style="color:${
        ele.IsPublish == 0 ? '#f00' : '#007acc'
      }">${ele.IsPublish == 0 ? '未直播' : '正在直播'}</span>
          </div>
          <div class="drone-info-2">
          <img src="${this.icons.droneHeight}" />
          <span>${parseFloat(ele.DroneAltitude).toFixed(1)}m</span>
          <img src="${this.icons.droneSpeed}" style="margin-left:18px;"/>
          <span>${parseFloat(ele.horizontal_velocity).toFixed(1)}m/s</span>
          <img src="${this.icons.droneDirection}" style="margin-left:18px;"/>
          <span>${parseInt(ele.DroneYaw)}'</span>
          </div>
          <div class="drone-info-3">
          <img src="${this.icons.dronePosition}" />
          <span>${parseFloat(ele.PointLatitude).toFixed(6)},${parseFloat(
        ele.PointLongitude
      ).toFixed(6)}</span>
          </div>
          </div>
          </div>
          `
      return content
    },
    // 生成无人机和用户dom
    generateUserMarker(ele) {
      console.log('sc', ele)
      // 用户
      let user_content = `
      <div class="marker-wrapper flex">
        <div class="user-name flex" class="if_name">${ele.name}</div>
        <div class="user-avatar flex" style="border-color: ${ele.comment}">
          <img src="${ele.head_icon_path || ele.head_icon}" />
        </div>
      </div>
      `
      let user_marker = new AMap.Marker({
        content: user_content, // 自定义点标记覆盖物内容
        position: [
          parseFloat(ele.UserLongitude) || 0,
          parseFloat(ele.UserLatitude) || 0,
        ], // 基点位置
        anchor: 'center', // 设置锚点方位
        // offset: new AMap.Pixel(-26, -47),
        draggable: false,
      })
      user_marker.uid = ele.user_id
      user_marker.data = ele
      user_marker.type = 'user'
      this.airPlaneMarkers.push(user_marker)
      if (ele.UserLongitude && ele.UserLatitude) {
        this.map.add(user_marker)
        user_marker.isAdded = true
      }
      //点击用户图标弹出对话框
      user_marker.on('click', (e) => {
        // console.log(e.target.data);
        this.$_bus.$emit('showChatOnMap', e.target.data, 'user')
      })
      if (ele.DRONEMODEL !== '未知') {
        // 无人机
        let plane_content = this.getPlaneContent(ele)
        // let plane_icon = this.getPlaneIcon();
        let L = ele.positionList.length
        let lastPosition =
          L > 0
            ? [
                parseFloat(ele.positionList[L - 1][0]) || 0,
                parseFloat(ele.positionList[L - 1][1]) || 0,
              ]
            : [0, 0]
        let plane_marker = new AMap.Marker({
          // icon: plane_icon,
          content: plane_content, // 自定义点标记覆盖物内容
          position: lastPosition, // 基点位置
          offset: new AMap.Pixel(-16, -16),
          draggable: false,
        })
        plane_marker.uid = ele.user_id
        plane_marker.type = 'plane'
        plane_marker.data = ele
        plane_marker.on('click', (e) => {
          this.$_bus.$emit('showChatOnMap', e.target.data, 'plane')
        })
        this.airPlaneMarkers.push(plane_marker)
        if (L > 0) {
          // 连上了无人机有数据才显示
          this.map.add(plane_marker)
          plane_marker.isAdded = true
        }
        // 连线
        if (!L) return
      }

      this.connectUserAndPlane(ele)
    },
    // 用户和无人机连接线
    connectUserAndPlane(ele) {
      let linePathArr = ele.positionList.unshift([
        parseFloat(ele.UserLongitude) || 0,
        parseFloat(ele.UserLatitude) || 0,
      ])
      let polyline = new AMap.Polyline({
        map: this.map,
        path: linePathArr,
        showDir: false,
        strokeColor: ele.comment, //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 1, //线宽
        // strokeStyle: "solid"  //线样式
      })
      polyline.uid = ele.user_id
      polyline.type = 'line'
      this.airPlaneMarkers.push(polyline)
    },
    // marker的数量更新了(一般是setting那边删减了) 地图同步更新
    updateMarkCounts() {
      if (!this.config.onEventHandler) return
      if (this.clickPointers.length !== this.config.markLength) {
        // 删除mark
        for (
          let i = 0, L = this.clickPointers.length - this.config.markLength;
          i < L;
          ++i
        ) {
          let markL = this.clickPointers.length
          this.map.remove(this.markList[markL - 1])
          this.clickPointers.splice(markL - 1, 1)
          this.markList.splice(markL - 1, 1)
          let lineTextLength = this.lineCenterTextList.length
          if (lineTextLength) {
            this.map.remove(this.lineCenterTextList[lineTextLength - 1])
            this.lineCenterTextList.splice(lineTextLength - 1, 1)
            this.map.remove(this.lineList[lineTextLength - 1])
            this.lineList.splice(lineTextLength - 1, 1)
          }
        }
        if (this.isLine) {
          if (this.activeMarker > this.clickPointers.length - 1) {
            let newActiveMarker = this.clickPointers.length
              ? this.clickPointers.length
              : -1
            this.$emit('updateActiveMarker', {
              activeMarker: newActiveMarker,
            })
            this.activeMarker = newActiveMarker - 1
            setTimeout(() => {
              this.toggleMarkerCss()
            }, 0)
          }
          this.createLine()
        } else {
          this.createLine(true)
          this.createPolygon()
        }
        this.throwMapData()
        console.log(`当前mark数量为${this.config.markLength}个`)
      }
    },
    // 自动规划航线
    renderRPAPolylineHandler() {
      if (this.clickPointers.length < 2) return
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      let setting = this.config.settingModalData
      //   let fov = (setting.defaultFov || 84) / 2;
      //   let rad = (fov * Math.PI) / 180;
      //   let hypotenuseLength = setting.height * Math.tan(rad) * 2;
      // let photeDis =
      //     hypotenuseLength * (3 / 5) * (1 - setting.courseOverlapRate / 100);
      // let planeSpaceDis =
      //     hypotenuseLength * (4 / 5) * (1 - setting.sideOverlapRate / 100);
      let photeDis = this.calculateonOverlapRateNum(setting)
      let planeSpaceDis = this.calculateBetweenOverlapRateNum(setting)
      let rotate = -90 + setting.courseAngle
      this.renderRPAPolyline(path, {
        stepRotate: rotate,
        spaceInp: planeSpaceDis,
      })
      // 更新照片数据
      let autoLinePoints = this.RPA_polyline.latlngs.map((item) => [
        item.lng,
        item.lat,
      ])
      let autoLineLength = Math.round(
        AMap.GeometryUtil.distanceOfLine(autoLinePoints)
      )
      // 传递自动规划航点数量
      this.$emit('getAutoLinePointsNum', autoLinePoints.length)
      let allPhotoCount = parseInt(autoLineLength / (photeDis || 1))
      this.config.settingModalData.photoGraphTimes = allPhotoCount
    },
    // 创建多边形
    createPolygon() {
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      if (this.polygon) {
        this.polygon.setPath(path)
        // this.map.remove(this.polygon);
        if (path.length > 1) {
          this.renderRPAPolylineHandler()
        } else {
          this.initRPADraw()
        }
      } else {
        // 创建区域实例
        this.polygon = new AMap.Polygon({
          path: path,
          map: this.map,
          strokeColor: '#E90000',
          strokeWeight: 1,
          strokeOpacity: 1,
          fillOpacity: 0.16,
          fillColor: '#E90000',
          zIndex: 50,
        })
        this.initRPADraw()
      }
      //  绘制无人机航线

      // return this.polygon;
    },
    // 绘制线或者图
    createLineOrArea() {
      if (this.isLine) {
        this.createLine()
        // let polyline = this.createLine();
        // this.map.add(polyline);
      } else {
        this.createLine(true)
        this.createPolygon()
        // let polygon = this.createPolygon();
        // this.map.add(polygon);
        // 缩放地图到合适的视野级别
        // this.map.setFitView([polygon])
      }
    },
    // 根据pointsList的数量来绘制
    drawLineOrArea(pointsList) {
      for (let i = 0, L = pointsList.length; i < L; i++) {
        let lng = pointsList[i][0]
        let lat = pointsList[i][1]
        let marker = this.createMarker({
          lng,
          lat,
          index: i,
          text: this.isLine ? this.clickPointers.length + 1 || '' : '',
        })
        this.markList.push(marker)
        this.clickPointers.push([lng, lat, marker._amap_id])
        this.createLineOrArea()
        this.map.add(marker)
        this.throwMapData()
      }
    },

    // 指点飞行的时候设置地图不可操作
    toggleeMapDrag(drag) {
      let mapOpts = {
        dragEnable: drag, // 地图是否可通过鼠标拖拽平移，默认为true
        keyboardEnable: drag, //地图是否可通过键盘控制，默认为true
        doubleClickZoom: drag, // 地图是否可通过双击鼠标放大地图，默认为true
        zoomEnable: drag, //地图是否可缩放，默认值为true
      }
      this.map.setStatus(mapOpts)
    },
    // 提交指挥官目标点
    submitSendCommander() {
      // 发送给全部在线飞手;
      if (this.config.onLineList.length == 0) {
        this.$Notice.warning({
          title: '暂无飞手在线!',
        })
        return
      } else if (this.sendCommanderOptions.local_data[0].lat == 0) {
        this.$Notice.warning({
          title: '请先点选目标点',
        })
        return
      } else {
        this.sendCommanderOptions.loading = true
        this.sendCommanderOptions.target_id = this.config.onLineList
          .map((ele) => ele.user_id)
          .toString()
        console.log('提交指挥官目标点', this.sendCommanderOptions.target_id)
        // post发送
        // this.$emit(
        //   'sendCommander',
        //   JSON.parse(JSON.stringify(this.sendCommanderOptions))
        // );

        // ws发送
        this.$emit(
          'sendSocketMsg',
          JSON.parse(JSON.stringify(this.sendCommanderOptions))
        )

        // 设置标记点名称;
        this.sendMarker.setLabel({
          offset: new AMap.Pixel(0, -2),
          content: `<div class="if_name">${this.targetName}</div>`, //设置文本标注内容
          direction: 'top',
        })
        this.changeName(this.showName)
        this.sendMarker = ''
      }
      // 这段会导致sendCommanderOptions参数被重置
      this.showMore.markerModal = false
      this.showMore.marker = false
      this.resetSendCommanderOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 重置参数
    resetSendMsgOptions() {
      this.sendMsgOptions.height = 0
      this.sendMsgOptions.selectAll = false
      this.sendMsgOptions.loading = false
      this.config.onLineList.forEach((ele) => {
        ele.selected = false
      })
      this.sendMsgOptions.target_id = []
    },
    resetSendCommanderOptions() {
      this.sendCommanderOptions.loading = false
      this.sendCommanderOptions.target_id = []
      this.sendCommanderOptions.local_data[0].lng = 0
      this.sendCommanderOptions.local_data[0].lat = 0
      this.targetIcon = navigation
    },
    // 选择指点飞行人员
    toggleSender(type, target) {
      if (type === 'one') {
        this.config.onLineList[target].selected =
          !this.config.onLineList[target].selected
      } else {
        this.config.onLineList.map((ele) => {
          ele.selected = !!type
          return ele
        })
      }
      // console.log(this.config.onLineList)
    },
    // 取消指点飞行
    cancelSendMsg(deleteMarker = true) {
      this.toggleeMapDrag(true)
      this.showMore.markerModal = false
      this.showMore.marker = false
      if (deleteMarker) {
        this.map.remove(this.sendMarker)
      }
      this.sendMarker = null
      this.resetSendMsgOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 取消指挥官目标点
    cancelSendCommander() {
      this.showMore.markerModal = false
      this.showMore.marker = false
      if (this.sendMarker) {
        this.map.remove(this.sendMarker)
        this.sendMarker = ''
        // this.sendMarkers.pop();
      }
      this.resetSendCommanderOptions()
      this.toggleMarkerClickOrDragEvent(false)
    },
    // 添加指挥官目标点
    addMarkerToSendCommander(position) {
      if (this.sendMarker) {
        this.map.remove(this.sendMarker)
      }
      // this.toggleeMapDrag(false);
      this.sendCommanderOptions.local_data[0].lat = position[1]
      this.sendCommanderOptions.local_data[0].lng = position[0]
      this.sendMarker = new AMap.Marker({
        position: new AMap.LngLat(position[0], position[1]),
        icon: this.targetIcon, // 添加 Icon 图标 URL
        offset: new AMap.Pixel(-15, -15),
      })
      this.map.add(this.sendMarker)
      this.showMore.markerModal = true
    },
    // 更换标记点类型
    targetType(type, typeStr) {
      this.targetIcon = type
      this.changeType = false
      if (this.sendMarker) {
        this.sendMarker.setIcon(this.targetIcon)
      }
      this.sendCommanderOptions.tag = typeStr
    },
    // 点击地图事件
    clickMap(event) {
      //   let { lng, lat } = event.lnglat;
      let lng, lat
      if (this.$online) {
        lng = event.lnglat.R
        lat = event.lnglat.Q
      } else {
        lng = event.lnglat.O
        lat = event.lnglat.P
      }
      // let { x, y } = event.pixel;
      if (this.showMore.marker) {
        this.toggleMarkerClickOrDragEvent(true)
        this.sendCommanderOptions.tag = 'mark_state_navigation'
        // this.sendMarker = null;
        this.targetRemarks = ''
        this.addMarkerToSendCommander([lng, lat])
      } else {
        this.drawLineOrArea([[lng, lat]])
      }
    },
    // marker的拖拽事件
    markerDragHandler(e) {
      let { lng, lat } = e.lnglat
      let dragMarkerIndex = this.clickPointers.findIndex((item) => {
        return item[2] === e.target._amap_id
      })
      this.$set(this.clickPointers[dragMarkerIndex], '0', lng)
      this.$set(this.clickPointers[dragMarkerIndex], '1', lat)
      this.createLineOrArea()
      this.throwMapData()
    },
    // setting那边改了每个单独点的数据 map这边同步更新
    syncSinglePointList() {
      this.markList.forEach((ele, index) => {
        let content = this.getCirCleContent({
          index: index,
          text: index + 1,
          isActive: index === this.activeMarker,
        })
        ele.setContent(content)
      })
      this.addCirclrMarkerClickHandler()
    },
    // 建线marker的dom内容增加了一部分
    getCirCleContent(params) {
      let content = ''
      //   let info = this.config.singlePointList[params.index] || {
      //     pointHeight: 0,
      //     pointAngle: 0,
      //   };
      if (this.isLine) {
        // content = `
        // <div class="marker-wrapper flex">
        //   <div class="plane-info user-name flex">
        //     <div>高度:&nbsp;${parseInt(info.pointHeight)}m</div>
        //     <div>角度:&nbsp;${parseInt(info.pointAngle)}°</div>
        //   </div>
        //   <div data-index="${params.text}" class="marker-cir-wrapper flex ${
        //   params.isActive ? 'm-active' : ''
        // }">${params.text}</div>
        // </div>
        // `;
        content = `
        <div class="marker-wrapper flex">
          <div data-index="${params.text}" class="marker-cir-wrapper flex ${
          params.isActive ? 'm-active' : ''
        }">${params.text}</div>
        </div>
        `
      } else {
        content = `<div class="marker-cir-wrapper flex">${params.text}</div>`
      }
      return content
    },
    // marker 工厂
    createMarker(params) {
      let content = this.getCirCleContent(params)
      let marker = new AMap.Marker({
        content: content, // 自定义点标记覆盖物内容
        position: [params.lng, params.lat], // 基点位置
        offset: this.isLine
          ? new AMap.Pixel(-15, -50)
          : new AMap.Pixel(-14, -15),
        draggable: true,
      })
      // marker.on("click", this.markerClickHandler);
      this.addCirclrMarkerClickHandler()
      // marker.on('dragend', this.markerDragHandler);
      marker.on(
        'dragging',
        _.throttle((e) => {
          this.markerDragHandler(e)
        }, 100)
      )
      return marker
    },
    // 只添加单个原点的点击事件
    addCirclrMarkerClickHandler(index) {
      if (!this.isLine || !this.config.onEventHandler) {
        return
      }
      let list = document.getElementsByClassName('marker-cir-wrapper')
      setTimeout(() => {
        Array.prototype.forEach.call(list, (ele) => {
          //   ele.hadAddClickEvent &&
          //     ele.addEventListener('click', this.markerClickHandler);
          //   ele.hadAddClickEvent = true;
          ele.addEventListener('click', this.markerClickHandler)
        })
      }, 1000) // marker构造是个异步过程 必须延迟添加事件
    },
    // line 工厂
    createLine(isDrawByArea = false) {
      let path = this.clickPointers.map((item) => {
        return new AMap.LngLat(item[0], item[1])
      })
      if (path.length < 2) {
        return
      }
      // 现在建线需要绘制2个点之间的距离了 所以需要每隔2个点单独绘制一根线
      let cb = (i) => {
        let m1, m2
        if (i === path.length - 1 && isDrawByArea) {
          m1 = this.markList[i]
          m2 = this.markList[0]
        } else {
          m1 = this.markList[i]
          m2 = this.markList[i + 1]
        }
        let p1 = m1.getPosition(),
          p2 = m2.getPosition()
        let textPos = p1.divideBy(2).add(p2.divideBy(2))
        let distance = Math.round(p1.distance(p2))
        // if (distance > 2000) {
        //   this.$Message.error('航点间距不能超过2000米');
        // }
        let pathInner = [p1, p2]
        if (this.lineList[i]) {
          this.map.remove(this.lineList[i])
          this.map.add(this.lineList[i])
          setTimeout(() => {
            this.lineList[i].setPath(pathInner)
          }, 100)
        } else {
          // 创建折线实例
          let polyline = new AMap.Polyline({
            map: this.map,
            path: path,
            borderWeight: isDrawByArea ? 0 : 1, // 线条宽度，默认为 1
            strokeColor: isDrawByArea ? 'transparent' : '#FF4949', // 线条颜色
            lineJoin: 'round', // 折线拐点连接处样式
          })
          this.lineList.push(polyline)
        }
        if (this.lineCenterTextList[i]) {
          this.lineCenterTextList[i].setText(distance + '米')
          this.lineCenterTextList[i].setPosition(textPos)
        } else {
          let text = new AMap.Text({
            text: distance + '米',
            position: textPos,
            map: this.map,
            style: {
              'background-color': '#29b6f6',
              'border-color': '#e1f5fe',
              'font-size': '12px',
            },
          })
          this.lineCenterTextList.push(text)
        }
      }
      for (let i = 0, L = path.length - 1; i < L; i++) {
        cb(i)
      }
      if (path.length > 1 && isDrawByArea) {
        // 最后再连一根线
        cb(path.length - 1)
      }
    },
    // 点击marker 更新高亮 同步setting那边activeMarker
    markerClickHandler(e) {
      if (!this.isLine || !this.config.onEventHandler) {
        return
      }
      // let ele = e.target.D.M.tg.children[0].children[1];
      let ele = e.target
      this.toggleMarkerCss(ele)
      // this.activeMarker = this.clickPointers.findIndex(item => {
      //   // return item[2] === e.target._amap_id;
      //   return item[2] === parseInt(e.target.dataset.index);
      // });
      this.activeMarker = parseInt(e.target.dataset.index) - 1
      this.$emit('updateActiveMarker', {
        activeMarker: this.activeMarker + 1,
      })
    },
    // 遍历更新所有marekr的样式
    toggleMarkerCss(activeEle) {
      let list = document.getElementsByClassName('marker-cir-wrapper')
      if (
        !activeEle &&
        this.activeMarker > -1 &&
        this.activeMarker <= list.length - 1
      ) {
        return
      }
      Array.prototype.forEach.call(list, (ele, index) => {
        if (activeEle && ele === activeEle) {
          ele.className = 'marker-cir-wrapper flex m-active'
        } else if (!activeEle && index === list.length - 1) {
          ele.className = 'marker-cir-wrapper flex m-active'
        } else {
          ele.className = 'marker-cir-wrapper flex'
        }
      })
    },
    // 计算建线数据
    calculateLine() {
      let points = this.clickPointers.map((item) => [item[0], item[1]])
      let length = AMap.GeometryUtil.distanceOfLine(points)
      return {
        lineCount: this.clickPointers.length,
        lineLength: length,
        points: points,
      }
    },
    calculateArea() {
      let points = this.clickPointers.map((item) => [item[0], item[1]])
      let airArea = Math.round(AMap.GeometryUtil.ringArea(points))
      let autoLinePoints = this.RPA_polyline.latlngs.map((item) => [
        item.lng,
        item.lat,
      ])
      let autoLineLength = Math.round(
        AMap.GeometryUtil.distanceOfLine(autoLinePoints)
      )
      console.log('当前区域面积为: ', airArea)
      console.log('当前自动规划航线长度为: ', autoLineLength)
      return {
        lineCount: this.clickPointers.length,
        airArea,
        autoLineLength,
        points: points,
      }
    },
    throwMapData() {
      if (this.isLine) {
        this.$emit('calculateLine', this.calculateLine())
      } else {
        this.$emit('calculateArea', this.calculateArea())
      }
    },

    toolHandler(type) {
      console.log(type)
      // 指点飞行和群体飞控禁止其他地图操作
      if (this.showMore.marker) {
        if (type != 'marker') {
          return
        }
      } else if (this.showMore.command) {
        if (type != 'command') {
          return
        }
      }

      if (type === 'marker') {
        this.showMore.marker = !this.showMore.marker
        this.targetName = '新增目标点'
        // 显隐左侧标记工具栏，打开地图addmark
        if (!this.showMore.markerModal) {
          this.showMore.markerModal = true
        } else {
          // 关闭左侧标记工具栏，清除
          this.showMore.markerModal = false
          if (this.sendMarker) {
            this.map.remove(this.sendMarker)
            this.sendMarker = null
            // this.sendMarkers.pop();
          }
        }

        this.toggleMarkerClickOrDragEvent(this.showMore.marker)
        return
      }
      // if (type == 'command') {
      //   console.log(111);
      //   this.showMore.command = !this.showMore.command;
      //   console.log(this.showMore.command);
      //   if (this.showMore.command) {
      //     this.rectangle = this.mouseTool.rectangle({
      //       strokeColor: 'red',
      //       strokeOpacity: 0.5,
      //       strokeWeight: 6,
      //       fillColor: 'blue',
      //       fillOpacity: 0.5,
      //       // strokeStyle还支持 solid
      //       strokeStyle: 'solid',
      //       // strokeDasharray: [30,10],
      //     });
      //   } else {
      //     this.mouseTool.close(false);
      //   }
      //   return;
      // }
      if (type === 'search') {
        this.showMore.search = !this.showMore.search
      }
      if (type === 'measure') {
        if (this.config.onEventHandler) {
          this.$Notice.warning({
            title: '请先关闭建线或者建图功能!',
          })
          return
        }
        this.showMore.measure = !this.showMore.measure
        if (this.showMore.measure) {
          this.draw()
        } else {
          this.mouseTool.close(true) // 设为true，关闭测量工具后清除地图上的测量
        }
      }
      if (type === 'hierarchy') {
        this.showMore.hierarchy = !this.showMore.hierarchy
      }
      if (type === 'zoomIn') {
        this.map.zoomIn()
      }
      if (type === 'zoomOut') {
        this.map.zoomOut()
      }
      if (type === 'fix') {
        this.backCenter()
      }
    },
    backCenter() {
      this.map.setCenter(this.$Center)
      // 关闭地图tool
      this.showMore.hierarchy = false
      this.showMore.command = false
      this.showMore.search = false
      this.showMore.measure = false
      this.showMore.marker = false
      this.showMore.markerModal = false
    },
    search() {
      if (!this.searchMsg) {
        this.map.setCenter(this.$Center)
        return
      }
      var _this = this
      var geocoder = new AMap.Geocoder()
      var address = this.searchMsg
      geocoder.getLocation(address, function (status, result) {
        if (status === 'complete' && result.geocodes.length) {
          var lnglat = result.geocodes[0].location
          // console.log("lnglat",lnglat);
          _this.map.setCenter([lnglat.lng, lnglat.lat]) //设置地图中心点
        } else {
          _this.$Message.error('根据地址查询位置失败')
        }
      })
    },
    // 测距
    stadiometryFn() {
      this.measureType = 'line'
      this.draw()
    },
    // 测面积
    mSurfaceFn() {
      this.measureType = 'area'
      this.draw()
    },
    // 设置目标点
    toggleMarkerClickOrDragEvent(on = true) {
      this.canExecEvent = on
      this.map.off('click', this.clickMap)
      this.markList.forEach((marker) => {
        marker.off('dragging', this.markerDragHandler)
        marker.setDraggable(false)
      })
      if (on) {
        this.map.on('click', this.clickMap)
        this.markList.forEach((marker) => {
          // marker.on("dragging", this.markerDragHandler);
          marker.setDraggable(true)
        })
      }
    },
    // 测量绘制方法
    draw() {
      var _this = this
      switch (_this.measureType) {
        case 'line': {
          _this.ruler = _this.mouseTool.rule({
            startMarkerOptions: {
              //开始图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/start.png'),
              }),
            },
            endMarkerOptions: {
              //结束图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/end.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            midMarkerOptions: {
              //中间点图标
              icon: new AMap.Icon({
                size: new AMap.Size(19, 31), //图标大小
                imageSize: new AMap.Size(19, 31),
                image: require('@/assets/img/Marker/mid.png'),
              }),
              offset: new AMap.Pixel(-9, -31),
            },
            lineOptions: {
              //线的样式
              strokeStyle: 'solid',
              strokeColor: '#FF4949',
              strokeOpacity: 1,
              strokeWeight: 2,
            },
            //同 RangingTool 的 自定义 设置，缺省为默认样式
          })
          break
        }
        case 'area': {
          _this.mouseTool.measureArea({
            strokeColor: '#80d8ff',
            fillColor: '#80d8ff',
            fillOpacity: 0.3,
            //同 Polygon 的 Option 设置
          })
          break
        }
      }
    },
    clearMarks() {
      this.clickPointers = []
      this.markList = []
      this.throwMapData()
    },
    // 切换3d
    switch3D() {
      this.mapType = this.mapType === '2d' ? '3d' : '2d'
      this.clearMarks()
      this.initMap()
      this.$emit('toggleMapType', this.mapType)
    },
    // //群体飞控
    // //画完选择区域后的处理
    // ControlAreaEnd(e) {
    //   let _this = this;
    //   _this.mouseTool.close();

    //   _this.rectangle = e.obj;
    //   let Path = _this.rectangle.getPath();
    //   let UAVList = _this.config.onLineList;

    //   _this.rectangle.setOptions({
    //     strokeColor: '#4169E1',
    //     fillColor: '#87CEEB',
    //   });

    //   // 开启编辑
    //   // let polyEditor = new AMap.PolyEditor(_this.map, _this.rectangle);
    //   // polyEditor.open( );

    //   console.log('mouseTool结束:', Path);
    //   console.log(UAVList);
    //   if (UAVList.length !== 0) {
    //     _this.UAVSelectedList = [];
    //     // DroneLatitude   DroneLongitude
    //     UAVList.forEach((item, ind) => {
    //       // track_tag
    //       if (
    //         _this.rectangle.contains([item.DroneLongitude, item.DroneLatitude])
    //       ) {
    //         item.dronePilotStatus = {
    //           text: '正在连接',
    //           color: '#ECD500',
    //         };
    //         _this.UAVSelectedList.push(item);
    //       }
    //     });

    //     if (_this.UAVSelectedList.length == 0) {
    //       _this.$Notice.warning({
    //         title: '所选区域没有无人机在线!',
    //       });
    //       _this.map.remove(_this.rectangle);
    //       _this.showMore.command = false;
    //     } else {
    //       // _this.$refs.sendControlMsg
    //       //构建自定义信息窗体
    //       _this.infoWindowCommand = new AMap.InfoWindow({
    //         isCustom: true,
    //         anchor: 'middle-left',
    //         content: _this.$refs.sendControlMsg,
    //       });
    //       var lng = Path[1].lng;
    //       var lat = Path[1].lat;
    //       _this.infoWindowCommand.open(_this.map, [lng, lat]);
    //     }
    //     console.log(_this.UAVSelectedList);
    //   } else {
    //     _this.$Notice.warning({
    //       title: '暂时没有无人机在线!',
    //     });
    //     _this.map.remove(_this.rectangle);
    //     _this.showMore.command = false;
    //   }
    // },
    // 发送控制请求
    // sendControlMsgFn() {
    //   console.log('发送控制请求');
    //   this.UAVSelectedList.forEach((item, ind) => {
    //     this.UAVSelectedList[ind].airPlaneAcceptedControlStatus =
    //       'waitingAccept';
    //     this.sendMsg('control', item);
    //   });
    //   setTimeout(() => {
    //     this.ControlModal = true;
    //     this.cancelSendControlbox();
    //   }, 600);
    // },
    // // 申请远程控制
    // sendMsg(type, info) {
    //   //先解除之前的控制
    //   let stop = {
    //     type: 'control',
    //     target_uid: info.user_id,
    //     to_name: info.name,
    //     from_uid: this.userInfo.user_id,
    //     from_name: this.userInfo.name,
    //     dataType: 1,
    //   };
    //   this.sendSocketMsg(stop);
    //   //再发送新的远程控制请求
    //   setTimeout(() => {
    //     let wsParams = {
    //       type: 'control',
    //       target_uid: info.user_id,
    //       to_name: info.name,
    //       from_uid: this.userInfo.user_id,
    //       from_name: this.userInfo.name,
    //       dataType: type === 'control' ? 0 : 1,
    //     };
    //     this.sendSocketMsg(wsParams);
    //     // console.log('申请喊话控制',wsParams);
    //   }, 300);
    // },
    // 关闭发送控制请求预览框
    // cancelSendControlbox() {
    //   this.infoWindowCommand.close();
    //   this.map.remove(this.rectangle);
    //   this.showMore.command = false;
    // },
    // 取消发送控制请求
    // cancelSendControlMsg() {
    //   console.log('取消发送控制请求');
    //   this.UAVSelectedList.forEach((item, ind) => {
    //     this.UAVSelectedList[ind].airPlaneAcceptedControlStatus = 'none';
    //     this.sendMsg('stop', item);
    //   });
    // },

    //快拼任务区域==============================
    //控制画面中心点
    // 113.170981,28.15978
    //画完区域后的处理
    rapidAreaEnd(e) {
      let _this = this
      _this.mouseTool.close()
      _this.rapidMouseTool = e.obj
      let Path = _this.rapidMouseTool.getPath()
      console.log(Path)
      // 拿到数据画出区域
      this.map.remove(e.obj)
      var northEast = new AMap.LngLat(Path[1].lng, Path[1].lat)
      var southWest = new AMap.LngLat(Path[3].lng, Path[3].lat)
      var bounds = new AMap.Bounds(southWest, northEast)
      this.rapidRectangle = new AMap.Rectangle({
        bounds: bounds,
        strokeColor: '#4169E1',
        strokeWeight: 6,
        strokeOpacity: 0.5,
        strokeDasharray: [30, 10],
        // strokeStyle还支持 solid
        strokeStyle: 'dashed',
        fillColor: '#87CEEB',
        fillOpacity: 0.2,
        cursor: 'pointer',
        zIndex: 50,
      })
      this.rapidRectangle.setMap(_this.map)
      var rapidRectangleEditor = new AMap.RectangleEditor(
        _this.map,
        this.rapidRectangle
      )
      //注册航线对象
      _this.rapidPath = new AMap.Polyline({
        strokeColor: '#0f0',
        strokeWeight: 2,
        strokeOpacity: 1,
      })
      this.rapidPath.setMap(_this.map)
      this.editPath = Path
      sessionStorage.setItem('editPath', JSON.stringify(Path))
      //画航线
      _this.rapidRPAPolylineHandler(Path)
      // 开启矩形编辑
      rapidRectangleEditor.open()
      //矩形被调节后触发
      rapidRectangleEditor.on('adjust', function (event) {
        console.log('矩形被调节', event.target.getPath())
        this.editPath = []
        this.editPath = event.target.getPath()
        sessionStorage.setItem(
          'editPath',
          JSON.stringify(event.target.getPath())
        )
        _this.rapidRPAPolylineHandler(event.target.getPath())
      })
      //矩形关闭调节后触发
      rapidRectangleEditor.on('end', function (event) {
        console.log('矩形关闭调节')
      })
    },
    // 快拼矩形自动规划航线==============================
    rapidRPAPolylineHandler(data) {
      var _this = this
      console.log('快拼矩形自动规划航线', data)
      let rapidData = this.config.rapidData
      let fov = (rapidData.defaultFov || 84) / 2
      let rad = (fov * Math.PI) / 180
      let hypotenuseLength = rapidData.height * Math.tan(rad) * 2
      console.log('hypotenuseLength:', hypotenuseLength)
      let photeDis =
        hypotenuseLength * (3 / 5) * (1 - rapidData.courseOverlapRate / 100)
      console.log('photeDis:', photeDis)
      // let planeSpaceDis = hypotenuseLength * (4/5) * (1 - (rapidData.sideOverlapRate / 100));
      // console.log('planeSpaceDis:', planeSpaceDis);
      let planeSpaceDis = _this.calculateBetweenOverlapRateNum(rapidData)
      console.log('间隔', planeSpaceDis)
      let rotate = rapidData.courseAngle
      let path = []
      data.forEach((item) => {
        var L = gcj02towgs84(item.lng, item.lat)
        var A = _this.computerThatLonLat(L[0], L[1], 180, planeSpaceDis / 2)
        path.push({
          lng: wgs84togcj02(A.lng, A.lat)[0],
          lat: wgs84togcj02(A.lng, A.lat)[1],
        })
      })
      // 路径集合结果
      console.log('路径集合结果', path)
      path = _this.mapLatlng2Apoint(path)
      console.log('路径集合结果2', path)
      let pathResult = cpRPA.setOptions({
        polygon: path,
        rotate: parseFloat(rotate) || 0,
        space: parseFloat(planeSpaceDis / 2) || 5,
        // space: planeSpaceDis || 5,
      })
      console.log('线段', pathResult, this.rapidPath)
      // 画航线
      this.rapidPath.setPath(pathResult)
      // 更新照片数据
      let autoLineLength = Math.round(AMap.GeometryUtil.distanceOfLine(path))
      console.log('autoLineLength:', autoLineLength)
      let allPhotoCount = parseInt(autoLineLength / (photeDis || 1))
      console.log('allPhotoCount:', allPhotoCount)
      this.config.rapidData.photoGraphTimes = allPhotoCount
    },
    //更新数据
    updateRapid() {
      this.$_bus.$emit('updateRapid', this.config.rapidData)
    },
    // //计算短边
    // calculateShortEdge(isLong) {
    //     var shortEdge = 0;
    //     if (isLong) {
    //         shortEdge = 3 / Math.sqrt(3 * 3 + 2 * 2);
    //     } else {
    //         shortEdge = 2 / Math.sqrt(3 * 3 + 2 * 2);
    //     }
    //     return shortEdge;
    // },
    // //计算地面长边
    // calculateGroundLongEdge() {
    //     var edge = this.calculateShortEdge(true);
    //     var fovNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan((84 / 2.0) * (Math.PI / 180));
    //     var fovLongsideNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan((84 / 2.0) * (Math.PI / 180)) *
    //         edge;
    //     return fovLongsideNum;
    // },
    // //计算地面短边
    // calculateGroundShortEdge() {
    //     var edge = this.calculateShortEdge(false);
    //     var fovshortsideNum =
    //         2 *
    //         (this.config.rapidData.height - this.config.rapidData.targetHeight) *
    //         Math.tan(((84 / 2.0) * Math.PI) / 180) *
    //         edge;
    //     return fovshortsideNum;
    // },
    // // 计算主航线间的间隔
    // calculateBetweenOverlapRateNum() {
    //     var num = this.calculateGroundShortEdge();
    //     return num * (1 - this.config.rapidData.courseOverlapRate / 100.0);
    // },
    // //计算主航线上的间隔
    // calculateonOverlapRateNum() {
    //     var num = this.calculateGroundLongEdge;
    //     return num * (1 - this.config.rapidData.sideOverlapRate / 100.0);
    // },
    changeName(e) {
      this.showName = e
      let x = document.querySelectorAll('.if_name')
      let y = document.querySelectorAll('.if_name_drone')
      if (x.length > 0) {
        x.forEach((element) => {
          element.style.display = e
        })
      }
      if (y.length > 0 && e == 'block') {
        y.forEach((element) => {
          element.style.visibility = 'visible'
        })
      }
      if (y.length > 0 && e == 'none') {
        console.log('1')
        y.forEach((element) => {
          element.style.visibility = 'hidden'
        })
      }
    },

    //项目和任务列表地图显示相应的位置和图形================================================================
    projectNav_ShowMarker(listData, ProjectType) {
      this.infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -30),
      })
      this.map.clearMap()
      //创建图标,绑定事件
      if (ProjectType == 3) {
        //当是显示任务列表
        listData.forEach((item) => {
          var iconImg
          if (item.tasktype == 'airroute') {
            iconImg = require('../../../assets/img/Marker/line_green.png')
          } else if (item.tasktype == 'figure') {
            iconImg = require('../../../assets/img/Marker/pic_red.png')
          }

          if (item.firstlocal) {
            let marker = new AMap.Marker({
              icon: new AMap.Icon({
                image: iconImg,
                size: new AMap.Size(25, 40),
              }),
              offset: new AMap.Pixel(-15, -40),
              position: [
                Number(eval(item.firstlocal).lng),
                Number(eval(item.firstlocal).lat),
              ],
            })
            marker.setLabel({
              offset: new AMap.Pixel(-50, -28),
              content: `<div class="mark_label"><P>${item.filetitle}</P></div>`,
            })
            marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.filetitle}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.name}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入任务</li>
                            </ul>
                        </div>
                          `
            marker.data = item
            marker.id = item.pm_id
            this.map.add(marker)
            marker.on('mouseover', this.projectNav_shouInfoWindow)
          }
        })
        this.map.setFitView()
      } else {
        if (ProjectType == 1) {
          var iconImg = require('../../../assets/img/Marker/Level_1_project.png')
        } else if (ProjectType == 2) {
          var iconImg = require('../../../assets/img/Marker/Level_2_project.png')
        }

        listData.forEach((item) => {
          let marker = new AMap.Marker({
            icon: new AMap.Icon({
              image: iconImg,
              size: new AMap.Size(25, 40),
            }),
            offset: new AMap.Pixel(-15, -40),
            position: [
              Number(eval(item.location)[0].lng),
              Number(eval(item.location)[0].lat),
            ],
          })
          marker.setLabel({
            offset: new AMap.Pixel(-50, -28),
            content: `<div class="mark_label"><P>${item.pm_name}</P></div>`,
          })
          marker.content = `<div class="marker-content" id=#${item.pm_id}>
                            <ul>
                                <li><i class="ivu-icon ivu-icon-ios-home-outline"></i>${item.pm_name}</li>
                                <li><i class="ivu-icon ivu-icon-ios-time-outline"></i>${item.data_create_time}</li>
                                <li><i class="ivu-icon ivu-icon-md-person"></i>${item.pm_administrator}</li>
                                <li><i class="ivu-icon ivu-icon-md-log-in"></i>点击进入项目</li>
                            </ul>
                        </div>
                          `
          marker.data = item
          marker.id = item.pm_id
          this.map.add(marker)
          marker.on('mouseover', this.projectNav_shouInfoWindow)
        })
        if (ProjectType == 2) {
          this.map.setFitView()
        }
      }
    },
    // 移入显示详细信息
    projectNav_shouInfoWindow(e) {
      var _this = this
      this.infoWindow.setContent(e.target.content)
      this.infoWindow.open(this.map, e.target.getPosition())
      // 点击信息进入工程,任务
      setTimeout(function () {
        var a = document.getElementById(`#${e.target.id}`)
        a.onclick = function () {
          _this.projectNav_IntoPproject(e.target.id, e.target.data)
        }
      }, 80)
    },
    //进入工程,任务
    projectNav_IntoPproject(pm_id, e) {
      console.log('//进入工程,任务')
      if (this.$store.state.ProjectListType == 0) {
        console.log('进入一级工程,展示二级工程列表')
        this.$_bus.$emit('Into_the_project', pm_id)
      } else if (this.$store.state.ProjectListType == 1) {
        console.log('进入二级工程,展示任务列表')
        this.$_bus.$emit('Into_the_task', pm_id)
      } else if (this.$store.state.ProjectListType == 2) {
        console.log('进入任务', pm_id, e)
        this.projectNav_IntoheTask(e)
      }
    },
    // 点击列表进入任务
    projectNav_IntoheTask(item) {
      var _this = this
      console.log('点击列表进入任务', item)
      sessionStorage.setItem('getTaskData', JSON.stringify(item))
      if (this.$route.query.Status == 2) {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'UpZip',
          },
        })
      } else {
        this.$router.push({
          path: '/resourceIndex/uploadingPage',
          query: {
            TaskID: item.id,
            status: 'imgUpload',
          },
        })
      }
    },
    //显示建图任务区域
    ShouBuildFigure() {
      let _this = this
      this.map.clearMap()
      let Data = JSON.parse(sessionStorage.getItem('getTaskData'))
      console.log(Data)
      let local_data = Data.local_data
      var path = []
      local_data.forEach((item) => {
        path.push([item.lng, item.lat])
      })

      //画点
      // path.forEach(item=>{
      //   let marker = new AMap.Marker({
      //     position: item
      //   });
      //   marker.DATA = Data;
      //   this.map.add(marker);
      //   marker.on("click", this.shouOrthophotoMap);
      // });

      // 画多边形
      var polygon = new AMap.Polygon({
        path: path,
        strokeColor: '#FF33FF',
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        zIndex: 50,
      })
      polygon.DATA = Data
      this.map.add(polygon)
      // 缩放地图到合适的视野级别
      this.map.setFitView([polygon])
    },

    /**
     * 虚拟护栏 NFzone
     */
    // 初始化虚拟护栏
    initNFzone() {
      let that = this
      this.$post(GetNotFlyArea(), {
        team_id: that.userInfo.team_id,
        showTeam: 0,
      }).then((res) => {
        if (res.code === 1) {
          res.data.forEach((element) => {
            let path = []
            element.local_data.forEach((ele) => {
              path.push(
                // new AMap.LngLat(
                //   window.gcj02towgs84(ele.lng,ele.lat)[0],
                //   window.gcj02towgs84(ele.lng, ele.lat)[1]
                // )
                new AMap.LngLat(ele.lng, ele.lat)
              )
            })

            let polygon = new AMap.Polygon({
              path: path,
              fillColor: '#c41a16',
              strokeColor: '#ff0000',
              fillOpacity: 0.3,
            })
            that.NFzone.push(polygon)
            that.map.add(polygon)
          })
        }
      })
    },

    // 查看飞行轨迹图片
    checkTrackImg(path) {},
    // 图片视频大图查看
    /**
     * 照片
     */
    // 查看大图
    bigImg(idx) {
      console.log(idx)
      this.bigIndex = idx
      this.imgModal = true
    },

    // 关闭弹窗
    closeImgModal() {
      this.imgModal = false
      this.bigIndex = 0
    },
  },
}
</script>

<style lang="scss">
.goBack {
  width: 78px;
  height: 78px;
  position: absolute;
  top: 30px;
  left: 30px;
  img {
    width: 100%;
    height: 100%;
  }
}

.marker-cir-wrapper {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(233, 0, 0, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  font-size: 16px;
  background-color: #fff;
  color: #000;
}

.m-active {
  border-color: #409eff;
}

.marker-wrapper {
  flex-direction: column;
  justify-content: center;
}

.marker-wrapper .plane-info div {
  white-space: nowrap;
}

.marker-wrapper .user-name {
  background: #333;
  opacity: 0.6;
  border-radius: 2px;
  margin-bottom: 4px;
  color: #fff;
  padding: 4px 8px;
  white-space: pre;
}

.marker-wrapper .user-avatar {
  overflow: hidden;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  border: 2px solid rgba(242, 58, 58, 1);
  border-radius: 50%;
  opacity: 1;
}

.marker-wrapper .user-avatar img {
  width: 30px;
  height: 30px;
}

.marker-wrapper .plane-info {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.marker-wrapper .plane-power-wrapper {
  width: 72px;
  height: 6px;
  background: rgba(255, 232, 228, 1);
  opacity: 1;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
}

.marker-wrapper .plane-power-wrapper .plane-power {
  width: 20px;
  height: 6px;
  background: rgba(255, 104, 91, 1);
  opacity: 1;
  border-radius: 5px;
}

.marker-wrapper .plane-avatar img {
  width: 32px;
  height: 32px;
}

.ControlModal1 {
  .ivu-modal-body {
    background-color: #e0e0e0;
  }
}

// 无人机图标样式
.drone-icon {
  position: relative;
  width: 32px;
  height: 32px;
  white-space: pre;

  &:hover > .drone-info {
    display: flex;
  }

  .drone-info {
    min-width: 200px;
    height: 90px;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    position: absolute;
    left: -200px;
    bottom: -90px;
    display: none;

    > div {
      display: flex;
      margin-bottom: 6px;
      align-items: center;
    }

    .drone-info-1 {
      justify-content: space-between;

      img {
        display: inline;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        border: 1px solid #ff685b;
        margin-right: 6px;
        vertical-align: middle;
      }
    }

    .drone-info-2 {
      justify-content: flex-start;

      img {
        display: inline;
        height: 13px;
      }

      span {
        margin-left: 3px;
      }
    }

    .drone-info-3 {
      img {
        display: inline;
        height: 11px;
        margin-right: 5px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.mapWrapper {
  width: 100%;
  height: 100%;
  z-index: $mapZIndex;
  //   position: relative;

  .commonMap {
    width: 100%;
    height: 100%;
  }

  .tool-box-wrapper {
    position: absolute;
    bottom: 0;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;

    .tool-box {
      margin-top: 20px;
      width: 30px;
      height: 30px;
      background: #000;
      opacity: 0.7;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        opacity: 1;
      }

      img {
        width: 18px;
      }

      .more-actions {
        opacity: 1;
        position: absolute;
        color: #fff;
        top: 0;
        right: 60px;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: #333;
        padding: 10px;

        .act-line {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          input {
            margin-right: 10px;
            margin-top: 2px;
          }

          span {
            white-space: nowrap;
          }
        }

        hr {
          margin-bottom: 10px;
        }
      }

      .measureBox {
        padding: 0;
        background-color: transparent;
        flex-direction: row;

        > div {
          white-space: nowrap;
          background-color: #333;
          opacity: 0.6;
          border-radius: 2px;
          margin-right: 10px;
          padding: 3px 10px;

          span {
            font-size: 16px;
            color: #fff;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        :nth-child(1) {
          opacity: 1;
        }
      }
    }
  }

  .searchBox {
    @include cl;
    top: 30px;
    width: 500px;
    height: 50px;

    /deep/ .ivu-input {
      height: 40px;
      border-radius: 50px;
      border: none;
      padding-left: 55px;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      box-shadow: 0 0 0 2px transparent;
      border: 1px solid #409eff;
    }

    /deep/ .ivu-input-prefix,
    .ivu-input-suffix {
      @include wh(60, 40);
      line-height: 40px;

      i {
        font-size: 25px;
        line-height: 40px;
      }
    }
  }

  .switch-3d {
    width: 50px;
    height: 50px;
    background: rgba(51, 51, 51, 1);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    position: absolute;
    top: 20px;
    cursor: pointer;

    img {
      width: 30px;
      height: 30px;
    }
  }

  .sendCommander {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    z-index: $tabZIndex;
    width: 240px;
    height: 100%;
    background: #f5f7fa;
    color: #333;
    font-size: 12px;

    > div {
      padding-left: 16px;
      border-bottom: 1px solid #ebebeb;
    }

    .top {
      font-size: 14px;

      height: 40px;
      line-height: 40px;
    }

    .coordinate {
      padding: 18px 16px;

      p {
        font-size: 14px;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        width: 170px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        line-height: 24px;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
      }
    }

    .mark_info {
      label {
        vertical-align: top;
      }

      input,
      textarea {
        // display: inline-block;
        width: 170px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        line-height: 24px;
        margin-bottom: 8px;
        margin-left: 8px;
        padding-left: 10px;
        outline: 0;
      }

      textarea {
        height: 72px;
        resize: none;
      }
    }

    .mark_style {
      position: relative;
      border-bottom: 0;

      button {
        width: 36px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 2px;
        color: #2196f3;
      }

      .icons {
        position: absolute;
        top: 88px;

        li {
          float: left;
          padding: 5px;
          background: #dbdbdb;
        }
      }

      .sendBtn {
        width: 88px;
        height: 28px;
        background: #2196f3;
        color: #fff;
        border-radius: 4px;
        margin-left: 8px;
        margin-top: 25px;
      }

      .sendCancel {
        background-color: #fff;
        color: #2196f3;
        margin-left: 16px;
      }
    }
  }

  .btns {
    justify-content: flex-start;
    font-size: 14px;

    button {
      height: auto;
      line-height: 1;
      font-size: 14px;
      padding: 10px 16px;
    }

    .check-all {
      transform: scale(0.8);
      white-space: nowrap;
    }
  }

  .sendControlMsg {
    padding: 20px 15px;
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    i {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 30px;
      color: #111;
      cursor: pointer;
    }

    h1 {
      font-size: 16px;
      color: #111;
      margin-top: 10px;
    }

    .dronePilotBox::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }

    .dronePilotBox {
      overflow: auto;
      min-width: 240px;
      max-height: 400px;

      .dronePilot {
        min-width: 240px;
        height: 80px;
        margin: 10px 0;
        padding: 10px;
        border-radius: 10px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);

        .headImg {
          width: 55px;
          height: 55px;
          border-radius: 55px;
        }

        .Divider {
          width: 2px;
          height: 100%;
          margin: 0 10px;
          background-color: #e0e0e0;
        }

        .msgBox {
          h2 {
            font-size: 18px;
            color: #111;
          }

          h3 {
            font-size: 12px;
            color: #111;
          }
        }
      }
    }
  }

  /deep/ .amap-marker-label {
    background: rgba(0, 0, 0, 0.7);
    padding: 0;
    color: #fff;
    border: 0;

    div {
      padding: 2px 4px;
    }
  }

  .show_name {
    position: absolute;
    top: 10px;
    background-color: #fff;
    transition: all 0.3s ease-in-out;
  }
}
</style>
