export default {
    name: 'cpRPA',
    data () {
        return {

        }
    },
    mounted () {

    },
    methods: {
        // 初始化
        initRPALine (map) {
            this.CPAMap = map;
            this.RPA_polyline = {
                layer: new AMap.Polyline({
                    map: map,
                    strokeColor: '#0f0',
                    strokeWeight: 2,
                    strokeOpacity: 1
                }),
                latlngs: []
            };
            cpRPA.setDistanceFn(this.distance);
            cpRPA.setLatlng2PxFn(this.latlng2Px);
            cpRPA.setPx2LatlngFn(this.px2Latlng);
        },
        initRPADraw () {
            this.RPA_polyline.latlngs = [];
            this.RPA_polyline.layer.setPath(this.RPA_polyline.latlngs);
        },
        renderRPAPolyline (path = [], params = { stepRotate: 0, spaceInp: 5 }) {
            // 那篇文章的方法 引入到这里来了
            this.RPA_polyline.latlngs = cpRPA.setOptions({
                polygon: path,
                rotate: parseFloat(params.stepRotate) || 0,
                space: parseFloat(params.spaceInp) || 5
            });
            this.RPA_polyline.layer.setPath(
                this.mapLatlng2Apoint(this.RPA_polyline.latlngs)
            )
        },
        mapLatlng2Apoint (arr) {
            var a = [];
            for (var i = 0; i < arr.length; i++) {
                a.push(new AMap.LngLat(arr[i].lng, arr[i].lat));
            }
            return a
        },
        distance (p1, p2) {
            return new AMap.LngLat(p1.lng, p1.lat).distance(new AMap.LngLat(p2.lng, p2.lat))
        },

        latlng2Px (latlng) {
            return this.CPAMap.lngLatToContainer(new AMap.LngLat(latlng.lng, latlng.lat))
        },

        px2Latlng (px) {
            return this.CPAMap.containerToLngLat(new AMap.Pixel(px[0], px[1]))
        },
        //建图航线算法========================================================
        //度转弧度
        rad (rad) {
            return (rad * Math.PI) / 180.0;
        },
        //弧度转度
        deg (deg) {
            return (deg * 180.0) / Math.PI;
        },
        //转换经纬度移动距离后的经纬度
        computerThatLonLat (lon, lat, brng = 180, dist) {
            var _this = this;
            /*
             * 大地坐标系资料WGS-84 长半径a=6378137 短半径b=6356752.3142 扁率f=1/298.2572236
             */
            /** 长半径a=6378137 */
            var a = 6378137;
            /** 短半径b=6356752.3142 */
            var b = 6356752.3142;
            /** 扁率f=1/298.2572236 */
            var f = 1 / 298.2572236;

            var alpha1 = _this.rad(brng);
            var sinAlpha1 = Math.sin(alpha1);
            var cosAlpha1 = Math.cos(alpha1);

            var tanU1 = (1 - f) * Math.tan(_this.rad(lat));
            var cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1);
            var sinU1 = tanU1 * cosU1;
            var sigma1 = Math.atan2(tanU1, cosAlpha1);
            var sinAlpha = cosU1 * sinAlpha1;
            var cosSqAlpha = 1 - sinAlpha * sinAlpha;
            var uSq = (cosSqAlpha * (a * a - b * b)) / (b * b);
            var A =
                1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
            var B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

            var cos2SigmaM = 0;
            var sinSigma = 0;
            var cosSigma = 0;
            var sigma = b * A,
                sigmaP = 2 * Math.PI;
            while (Math.abs(sigma - sigmaP) > 1e-12) {
                cos2SigmaM = Math.cos(2 * sigma1 + sigma);
                sinSigma = Math.sin(sigma);
                cosSigma = Math.cos(sigma);
                var deltaSigma =
                    B *
                    sinSigma *
                    (cos2SigmaM +
                        (B / 4) *
                        (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
                            (B / 6) *
                            cos2SigmaM *
                            (-3 + 4 * sinSigma * sinSigma) *
                            (-3 + 4 * cos2SigmaM * cos2SigmaM)));
                sigmaP = sigma;
                sigma = dist / (b * A) + deltaSigma;
            }

            var tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
            var lat2 = Math.atan2(
                sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
                (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp)
            );
            var lambda = Math.atan2(
                sinSigma * sinAlpha1,
                cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1
            );
            var C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
            var L =
                lambda -
                (1 - C) *
                f *
                sinAlpha *
                (sigma +
                    C *
                    sinSigma *
                    (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
            var revAz = Math.atan2(sinAlpha, -tmp);
            return {
                lng: lon + _this.deg(L),
                lat: _this.deg(lat2),
            };
        },
        //计算短边
        calculateShortEdge (isLong) {
            var shortEdge = 0;
            if (isLong) {
                shortEdge = 4 / Math.sqrt(3 * 3 + 4 * 4);
            } else {
                shortEdge = 3 / Math.sqrt(3 * 3 + 4 * 4);
            }
            return shortEdge;
        },
        //计算地面长边
        calculateGroundLongEdge (Data) {
            var edge = this.calculateShortEdge(true);
            var fovNum =
                2 *
                (Data.height - Data.targetHeight) *
                Math.tan((84 / 2.0) * (Math.PI / 180));
            var fovLongsideNum =
                2 *
                (Data.height - Data.targetHeight) *
                Math.tan((84 / 2.0) * (Math.PI / 180)) *
                edge;
            console.log("fovLongsideNum", fovLongsideNum, fovNum);
            return fovLongsideNum;
        },
        //计算地面短边
        calculateGroundShortEdge (Data) {
            var edge = this.calculateShortEdge(false);
            var fovshortsideNum =
                2 *
                (Data.height - Data.targetHeight) *
                Math.tan(((84 / 2.0) * Math.PI) / 180) *
                edge;
            console.log("fovshortsideNum", fovshortsideNum, fovshortsideNum / edge);
            console.log("tan", Math.tan(((84 / 2.0) * Math.PI) / 180))
            console.log("Data", Data)
            return fovshortsideNum;
        },
        // 计算主航线间的间隔
        calculateBetweenOverlapRateNum (Data) {
            var num = this.calculateGroundShortEdge(Data);
            console.log("计算主航线间的间隔", num * (1 - Data.sideOverlapRate / 100.0))
            return num * (1 - Data.sideOverlapRate / 100.0);
        },
        //计算主航线上的间隔
        calculateonOverlapRateNum (Data) {
            var num = this.calculateGroundLongEdge(Data);
            return num * (1 - Data.courseOverlapRate / 100.0);
        },
    }
}
